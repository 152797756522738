import { bannerData } from "../../../globals/banner";
import Banner from "../../sections/common/banner";
import SectionClients2 from "../../sections/home/index2/section-clients2";

function TestimonialsPage() {
  return (
    <>
      <Banner _data={bannerData.testimonials} />

      <div className="section-full p-t100 p-b0 bg-white mobile-page-padding">
        {/* TESTIMONIAL START */}
        <div className="container">
          <div className="section-content">
            {/* TITLE START */}
            <div className="section-head center">
              <div className="sx-head-s-title">Our Testimonials</div>
              <div className="sx-head-l-title">
                <h2 className="sx-title">What Our Clients Say?</h2>
              </div>
            </div>
            {/* TITLE END */}
            <div className="masonry-outer mfp-gallery news-grid clearfix row">
              {/* COLUMNS 1 */}
              <div className="masonry-item col-lg-4 col-md-6 col-sm-12 m-b30">
                <div className="testimonial-3 hover-animation-1 sx-bg-light">
                  <div className="testimonial-detail">
                    <div className="testimonial-user-info">
                      <span className="testimonial-position">CEO Of MerriView</span>
                      <h4 className="testimonial-name">Edward</h4>
                    </div>
                  </div>
                  <div className="testimonial-text">
                    <p>
                      DevRolin's innovative solutions transformed our vision into a functional app. Their expertise and commitment to quality made the entire process effortless.
                    </p>
                  </div>
                  <div className="testimonial-footer">
                    <div className="testimonial-quote sx-text-primary">
                      <i className="flaticon-quote" />
                    </div>
                    <ul className="sx-rating-st1">
                      <li><span><i className="fa fa-star" /></span></li>
                      <li><span><i className="fa fa-star" /></span></li>
                      <li><span><i className="fa fa-star" /></span></li>
                      <li><span><i className="fa fa-star" /></span></li>
                      <li><span><i className="fa fa-star" /></span></li>
                    </ul>
                    <span className="sx-review-count">(5 Stars)</span>
                  </div>
                </div>
              </div>
              {/* COLUMNS 2 */}
              <div className="masonry-item col-lg-4 col-md-6 col-sm-12 m-b30">
                <div className="testimonial-3 hover-animation-1 sx-bg-light">
                  <div className="testimonial-detail">
                    <div className="testimonial-user-info">
                      <span className="testimonial-position">IT Director</span>
                      <h4 className="testimonial-name">John M.</h4>
                    </div>
                  </div>
                  <div className="testimonial-text">
                    <p>
                      Thanks to DevRolin's strategic AI solutions, our operational efficiency has soared. The team's professionalism and attention to detail were commendable!
                    </p>
                  </div>
                  <div className="testimonial-footer">
                    <div className="testimonial-quote sx-text-primary">
                      <i className="flaticon-quote" />
                    </div>
                    <ul className="sx-rating-st1">
                      <li><span><i className="fa fa-star" /></span></li>
                      <li><span><i className="fa fa-star" /></span></li>
                      <li><span><i className="fa fa-star" /></span></li>
                      <li><span><i className="fa fa-star" /></span></li>
                      <li><span><i className="fa fa-star" /></span></li>
                    </ul>
                    <span className="sx-review-count">(5 Stars)</span>
                  </div>
                </div>
              </div>
              {/* COLUMNS 3 */}
              <div className="masonry-item col-lg-4 col-md-6 col-sm-12 m-b30">
                <div className="testimonial-3 hover-animation-1 sx-bg-light">
                  <div className="testimonial-detail">
                    <div className="testimonial-user-info">
                      <span className="testimonial-position">Marketing Manager</span>
                      <h4 className="testimonial-name">Emily Peaches</h4>
                    </div>
                  </div>
                  <div className="testimonial-text">
                    <p>
                      DevRolin took our outdated systems and revitalized them with modern solutions. Their approach was tailored and highly effective—truly a game changer!
                    </p>
                  </div>
                  <div className="testimonial-footer">
                    <div className="testimonial-quote sx-text-primary">
                      <i className="flaticon-quote" />
                    </div>
                    <ul className="sx-rating-st1">
                      <li><span><i className="fa fa-star" /></span></li>
                      <li><span><i className="fa fa-star" /></span></li>
                      <li><span><i className="fa fa-star" /></span></li>
                      <li><span><i className="fa fa-star" /></span></li>
                      <li><span><i className="fa fa-star" /></span></li>
                    </ul>
                    <span className="sx-review-count">(5 Stars)</span>
                  </div>
                </div>
              </div>
              {/* COLUMNS 4 */}
              <div className="masonry-item col-lg-4 col-md-6 col-sm-12 m-b30">
                <div className="testimonial-3 hover-animation-1 sx-bg-light">
                  <div className="testimonial-detail">
                    <div className="testimonial-user-info">
                      <span className="testimonial-position">Business Owner</span>
                      <h4 className="testimonial-name">Delilah Brooklyn</h4>
                    </div>
                  </div>
                  <div className="testimonial-text">
                    <p>
                    Working with DevRolin was a remarkable experience. They delivered a web app that exceeded expectations through excellent support and innovation.                  
                      </p>                  </div>
                  <div className="testimonial-footer">
                    <div className="testimonial-quote sx-text-primary">
                      <i className="flaticon-quote" />
                    </div>
                    <ul className="sx-rating-st1">
                      <li><span><i className="fa fa-star" /></span></li>
                      <li><span><i className="fa fa-star" /></span></li>
                      <li><span><i className="fa fa-star" /></span></li>
                      <li><span><i className="fa fa-star" /></span></li>
                      <li><span><i className="fa fa-star" /></span></li>
                    </ul>
                    <span className="sx-review-count">(5 Stars)</span>
                  </div>
                </div>
              </div>
              {/* COLUMNS 5 */}
              <div className="masonry-item col-lg-4 col-md-6 col-sm-12 m-b30">
                <div className="testimonial-3 hover-animation-1 sx-bg-light">
                  <div className="testimonial-detail">
                    <div className="testimonial-user-info">
                      <span className="testimonial-position">Director Operations</span>
                      <h4 className="testimonial-name">Lisa Howard</h4>
                    </div>
                  </div>
                  <div className="testimonial-text">
                    <p>
                      DevRolin's custom solutions have significantly enhanced our productivity. Their integration of systems was flawless, truly tailored to our business needs.
                    </p>
                  </div>
                  <div className="testimonial-footer">
                    <div className="testimonial-quote sx-text-primary">
                      <i className="flaticon-quote" />
                    </div>
                    <ul className="sx-rating-st1">
                      <li><span><i className="fa fa-star" /></span></li>
                      <li><span><i className="fa fa-star" /></span></li>
                      <li><span><i className="fa fa-star" /></span></li>
                      <li><span><i className="fa fa-star" /></span></li>
                      <li><span><i className="fa fa-star" /></span></li>
                    </ul>
                    <span className="sx-review-count">(5 Stars)</span>
                  </div>
                </div>
              </div>
              {/* COLUMNS 6 */}
              <div className="masonry-item col-lg-4 col-md-6 col-sm-12 m-b30">
                <div className="testimonial-3 hover-animation-1 sx-bg-light">
                  <div className="testimonial-detail">
                    <div className="testimonial-user-info">
                      <span className="testimonial-position">COO</span>
                      <h4 className="testimonial-name">Michael Shawn</h4>
                    </div>
                  </div>
                  <div className="testimonial-text">
                    <p>
                      Partnering with DevRolin was a transformative choice. Their proactive communication and tailored support ensured we met all our project goals successfully.
                    </p>
                  </div>
                  <div className="testimonial-footer">
                    <div className="testimonial-quote sx-text-primary">
                      <i className="flaticon-quote" />
                    </div>
                    <ul className="sx-rating-st1">
                      <li><span><i className="fa fa-star" /></span></li>
                      <li><span><i className="fa fa-star" /></span></li>
                      <li><span><i className="fa fa-star" /></span></li>
                      <li><span><i className="fa fa-star" /></span></li>
                      <li><span><i className="fa fa-star" /></span></li>
                    </ul>
                    <span className="sx-review-count">(5 Stars)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* TESTIMONIAL END */}
      </div>

      {/* CLIENTS SECTION START */}
      <SectionClients2 />
      {/* CLIENTS SECTION END */}
    </>
  );
}

export default TestimonialsPage;
