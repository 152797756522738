import ItodoImage from "../../../elements/itodo-img";

function SectionAboutCompany2Inner() {
  return (
    <>
      <div className="sx-about-bx3-content-wrap">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="sx-about-bx3-media">
              <div className="sx-dot-bx1">
                <ItodoImage src="images/about/dotted-pic2.png" alt="Decorative Dots" />
              </div>
              <div className="sx-dot-bx2">
                <ItodoImage src="images/about/dotted-pic2.png" alt="Decorative Dots" />
              </div>
              <div className="sx-ab3-img-outer">
                {/* <div className="sx-ab3-media">
                                    <ItodoImage src="images/about/p2.jpg" alt="media" />
                                </div> */}
              </div>
              <div className="sx-ab-circle-box">
                <div className="sx-ab-circle-box-content">
                  <span className="sx-exp">10 Years Experience</span>
                  <div className="sx-exp-title-outer">
                    <span className="sx-exp-title-1">IT</span>
                    <span className="sx-exp-title-2">Solutions</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="sx-about-bx3-content">
              <h2 className="sx-title">
                Discover Why We Are Your Preferred Partner
              </h2>
              <span className="sx-title-2">
                Innovative IT Solutions Tailored to Your Needs.
              </span>
              <p>
                At DevRolin, we specialize in delivering high-quality web applications that exceed your expectations. Our expert team combines creativity with exceptional support, ensuring a seamless experience from concept to completion.
              </p>
              <p>
                With over a decade of experience, we provide tailored IT solutions that drive business success. Whether you need a web app, digital marketing, or data analytics, our comprehensive services cater to all your requirements.
              </p>
              <div className="row sx-about-icon-bx3-column ">
                <div className="col-lg-5 col-md-5">
                  <div className="sx-icon-bx-3">
                    <div className="sx-media">
                      <i className="flaticon-incoming-call" />
                    </div>
                    <div className="sx-icon-bx-info">
                      <span className="sx-icon-bx-title-info">
                        Contact Phone
                      </span>
                      <span className="sx-icon-bx-title">
                        +971522347966
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-7">
                  <div className="sx-icon-bx-3">
                    <div className="sx-media">
                      <i className="flaticon-email-1" />
                    </div>
                    <div className="sx-icon-bx-info">
                      <span className="sx-icon-bx-title-info">
                        Contact Mail
                      </span>
                      <span className="sx-icon-bx-title relative left-5">
                        support@devrolin.com
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hilite-title text-left p-l50 text-uppercase">
        <strong>About Us</strong>
      </div>
    </>
  );
}

export default SectionAboutCompany2Inner;
