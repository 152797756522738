import React from 'react';
import { Helmet } from 'react-helmet';
import Banner from "../../sections/common/banner";
import SectionFAQTabs from "../../sections/company/faq/section-faq-tabs";
import SectionFAQContent from "../../sections/company/faq/section-faq-content";
import { bannerData } from "../../../globals/banner";

function FAQCombined() {
  return (
    <>
      <div className="section-full p-t100 p-b0 sx-bg-white sx-faq--outer">
        <div className="container">
          <div className="aon-faq-row-section">
            <div className="sx-faq-style-1-wrap">
              <SectionFAQTabs />
              <SectionFAQContent />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function FAQPage() {
  return (
    <>
      <Helmet>
        <title>Frequently Asked Questions | DevRolin IT Solutions</title>
        <meta name="description" content="Unlock the power of DevRolin's IT solutions! Discover how we solve your tech challenges, boost your business, and stay ahead of the curve. Get expert answers to your burning questions now." />
        <meta name="keywords" content="FAQ, IT solutions, DevRolin, frequently asked questions, IT services, tech support" />
        <link rel="canonical" href="https://www.devrolin.com/company/faq" />
        <meta property="og:title" content="Frequently Asked Questions | DevRolin IT Solutions" />
        <meta property="og:description" content="Unlock the power of DevRolin's IT solutions! Discover how we solve your tech challenges, boost your business, and stay ahead of the curve. Get expert answers to your burning questions now." />
        <meta property="og:url" content="https://www.devrolin.com/company/faq" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Banner _data={bannerData.faq} />
      <FAQCombined />
    </>
  );
}

export default FAQPage;
