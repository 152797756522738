import Banner from "../../../sections/common/banner";
import SectionWhyChooseUs1 from "../../../sections/home/index/section-why-choose-us1";
import SectionAboutCompany1 from "../../../sections/home/index/section-about-company1";
import SectionVideo2 from "../../../sections/home/index2/section-video2";
import SectionWhyChooseUs4 from "../../../sections/company/about/section-why-choose-us4";
import SectionClients2 from "../../../sections/home/index2/section-clients2";
import { bannerData } from "../../../../globals/banner";
import { useEffect } from "react";
import { loadScript } from "../../../../globals/constants";
import { Helmet } from "react-helmet";

function ServicesPage() {
    useEffect(() => {
        loadScript("js/custom.js")
    })

    return (
        <>
            <Helmet>
                <title>Revolutionary IT Services | DevRolin's Tech Wizardry</title>
                <meta name="description" content="Unleash your business potential with DevRolin's game-changing IT services. From mind-bending AI to bespoke software sorcery, we're your secret weapon for digital domination." />
                <meta name="keywords" content="IT wizardry, revolutionary software, AI mastery, DevRolin magic, tech transformation, business evolution" />
                <link rel="canonical" href="https://www.devrolin.com/itsolutions/services" />
                <meta property="og:title" content="Revolutionary IT Services | DevRolin's Tech Wizardry" />
                <meta property="og:description" content="Catapult your business into the future with DevRolin's mind-blowing IT solutions. Our tech geniuses craft custom software, weave AI magic, and conjure cutting-edge innovations to skyrocket your success." />
                <meta property="og:url" content="https://www.devrolin.com/itsolutions/services" />
                <meta property="og:type" content="website" />
            </Helmet>
            <Banner _data={bannerData.services} />
            <SectionWhyChooseUs1 />
            <SectionAboutCompany1 />
            <SectionVideo2 />
            <SectionWhyChooseUs4 />
            <SectionClients2 />
        </>
    )
}

export default ServicesPage;